import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../components/Header';

import {
  SectionComponent,
  HeadlineComponent,
  ParagraphComponent,
  ButtonComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const LightParagraph = styled.p`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: ${({ pd }) => pd || 0};
  text-align: ${({ alignment }) => alignment || 'center'};
  font-size: 1.2rem;
  font-weight: 300;
  @media screen and (max-width: 768px) {
    } 
  
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ mg }) => mg || '0.5rem 2rem'};
  padding: ${({ pd }) => pd || '0'};

  @media screen and (max-width: 768px) {
      flex-direction: column;
    }
`;
export const Container2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ mg }) => mg || '0.5rem 2rem'};
  padding: ${({ pd }) => pd || '0'};

  @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
`;

export const Card = styled.div`
  flex: 1;
  margin: 0;
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};
  padding: ${({ pd }) => pd || 0};

  @media screen and (max-width: 768px) {

    flex: 0;
    width: 350px;
    margin: 0rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    img {
      max-width: 350px !important;
    }
  }
`;

function ServicesPage() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "services-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      service1: file(relativePath: { eq: "home-services-5.jpg" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      service2: file(relativePath: { eq: "home-services-6.jpg" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      service3: file(relativePath: { eq: "home-services-3.jpg" }) {
        childImageSharp {
          fixed(width: 500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Services"
        link="https://teampoker.com/services"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header
        image={data.background.childImageSharp.fluid}
        text="MARKET INITIATIVES"
      />
      <SectionComponent mg="1.5rem" id="live-poker">
        <HeadlineComponent>Live Poker Room Software</HeadlineComponent>
        <Container mg="1.5rem">
          <Card mr="2rem">
            <Img
              style={{ display: 'block' }}
              fixed={data.service2.childImageSharp.fixed}
            />
            <LightParagraph alignment="left" pd="0.75rem">
              Live Poker Software
            </LightParagraph>
          </Card>
          <div>
            <ParagraphComponent align="left" pb="1rem">
              Team Poker® has partnered with the most professional Live Poker
              Room software providers available. Their Poker Room Management and
              Tournament Software is available now to any large or small Casino
              and Poker Room. The poker software is the best in the World and
              can handle all aspects of a poker room management.
            </ParagraphComponent>
            <ParagraphComponent align="left" pb="2rem">
              Our Live Poker Room Software Partners meet and provide all
              requirements of Team Poker® including: (1) Team and Individual
              Scoring and Payouts; (2) Poker Team Handicapping; and, (3) Non-
              Contact Seating Protocol. It is easy to run Team Poker® Events of
              any size from small Satellites to Massively Large Tournaments with
              thousands of players. Our Partners offer Team Poker® limited
              licensing from FREE to Monthly & Yearly Subscriptions.
            </ParagraphComponent>
            <Link to='/contact'>
            <ButtonComponent>Poker Room Software</ButtonComponent>
            </Link>
          </div>
        </Container>
      </SectionComponent>
      <SectionComponent mg="1.5rem" id="online-poker">
        <HeadlineComponent>Online Poker Rooms</HeadlineComponent>
        <Container2 mg="1.5rem">
          <div>
            <ParagraphComponent align="left" pb="1rem">
              We are looking to Partner with Online Poker Rooms that can fully
              implement the TeamPoker® Requirements and the Poker Sports Team
              Model in their software. Providers must meet capabilities of the
              strict TeamPoker® requirements including the No-Contact Seating
              Protocol, Scoring, and Team Handicapping.
            </ParagraphComponent>
            <ParagraphComponent align="left" pb="2rem">
              TeamPoker.com has implemented the Team Poker® Requirements in our
              Charity Prototype and it can be used as an example of how to
              implement the Sports Team Model including: establishing teams and
              ownership rights, changing teams, tournament entry, initial &
              consolidation seating protocol, and ranking individuals and teams.
              The format mirrors the professional sports league model. Contact
              us about licensing.
            </ParagraphComponent>
            <Link to='/contact'>

            <ButtonComponent>Online Poker Rooms</ButtonComponent>
            </Link>
          </div>
          <Card ml="2rem">
            <Img
              style={{ display: 'block' }}
              fixed={data.service1.childImageSharp.fixed}
            />
            <LightParagraph alignment="left" pd="0.75rem">
              Online Poker Room
            </LightParagraph>
          </Card>
        </Container2>
      </SectionComponent>
      <SectionComponent mg="1.5rem" id="licenses">
        <HeadlineComponent>Championship Licenses</HeadlineComponent>
        <Container mg="1.5rem">
          <Card mr="2rem">
            <Img
              style={{ display: 'block' }}
              fixed={data.service3.childImageSharp.fixed}
            />
            <LightParagraph alignment="left" pd="0.75rem">
              Championship Licenses
            </LightParagraph>
          </Card>
          <div>
            <ParagraphComponent align="left" pb="1rem">
              We also are looking for partners for both LIVE and ONLINE poker
              rooms in support of The WORLD POKER TEAM CHAMPIONSHIP™ (WPTC) -
              SERIES. This Series will be conducted by location: Home Games,
              City, State, Region, Country, Continent, and World Championship
              Finals for both Live and Online Poker Rooms.
            </ParagraphComponent>
            <ParagraphComponent align="left" pb="2rem">
              The WPTC Series will be designed to implement the Poker Sports
              Model for Poker Teams of Various Sizes, Classes, and Divisions all
              leading to The WORLD POKER TEAM CHAMPIONSHIP™ - Finals for Live
              and Online Poker. All Production and Video Licenses for
              Championship Licenses are issued separately. Contact Team Poker
              LLC for requirements and Licensing Partnerships for Team Poker®
              Championships.
            </ParagraphComponent>
            <Link to='/contact'>
            <ButtonComponent>Licenses</ButtonComponent>
            </Link>
          </div>
        </Container>
      </SectionComponent>
    </Layout>
  );
}

export default ServicesPage;
